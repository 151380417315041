import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';

const error = () => {
  return (
    <div id="wrapper">
      <Helmet>
        <title>404 | Not Found</title>
      </Helmet>
      <Layout>
        <div
          style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <h1 className="h1">Page Not Found</h1>
          <p>Please click above link to navigate to the website</p>
        </div>
      </Layout>
    </div>
  );
};

export default error;
